import { h } from 'preact'
import { CalculatorSavings } from 'models/apps'
import { BankData } from 'src/assets/scripts/models/market'
import { useState } from 'preact/hooks'
import BankOptionsElement from './bank-options-element'
import Savings from './savings-element'
import { i18n } from 'i18n/i18n'

interface InteractiveElementsProps {
  sellCurrency: string
  banksData: BankData[]
  savings: CalculatorSavings
}

const InteractiveElements = ({ banksData, sellCurrency, savings }: InteractiveElementsProps) => {
  const [isCompareVisibile, setCompareVisibility] = useState<boolean>(false)

  const toggleVisibility = () => {
    setCompareVisibility(!isCompareVisibile)
  }

  const howItWorks = `${i18n('calculator.howItWorks')}`

  return (
    <div className="hero-form__options-holder">
      {isCompareVisibile && (
        <BankOptionsElement
          banksData={banksData}
          sellCurrency={sellCurrency}
        />
      )}
      <div className="calculator-chart__compare-holder">
        <div
          className="calculator-chart__compare-text-element chevron-right"
          onClick={toggleVisibility}
        >
          {isCompareVisibile ? `${i18n('calculator.banksComapreClose')}` : `${i18n('calculator.banksComapreOpen')}`}
        </div>
        {howItWorks && (
          <a
            className="calculator-chart__compare-text-element"
            href="/jak-dziala-kalkulator/"
          >
            <span>{howItWorks}</span>
          </a>
        )}
      </div>
      <Savings
        sellCurrency={sellCurrency}
        savings={savings}
      />
    </div>
  )
}

export default InteractiveElements
